<template>
    <v-card class="ma-0 pa-0" flat style="height: 100%; background-color: transparent !important;">
        <v-card-text class="ma-0 pa-0">
            <v-chart class="ma-0 pa-0 chart" :key="updateKey" :option="option" />
        </v-card-text>
    </v-card>
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent,
    MarkAreaComponent,
    DataZoomComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { max } from "zrender/lib/core/vector";

use([
    CanvasRenderer,
    LineChart,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent,
    MarkAreaComponent,
    DataZoomComponent
]);

export default {
    props: ['data', 'defaultChart', 'labels', 'legend', 'title', 'textColor', 'xAxisLabel', 'yAxisLabel', 'color', 'updateKey'],
    components: {
        VChart
    },
    mounted(){
        // console.log("Data",this.data)
    },
    provide: {
        // [THEME_KEY]: "dark"
    },
    data() {
        return {
            option: {
                title: {
                    text: 'Telemetry',
                    show: true,
                    textStyle: {
                        color: '#e2e2e2',
                        fontSize: 14,
                        fontWeight: '400'
                    },
                    top: '1%',
                    left: '1%'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: this.legend,
                    textStyle: {
                        color: this.textColor
                    },
                },
                markArea: {

                    // data: [
                    //     [{
                    //         name: 'Weekend',
                    //         xAxis: '2021-07-10'
                    //     }, {
                    //         xAxis: '2021-07-11'
                    //     }]
                    // ]
                },
                grid: {
                    left: '40px',
                    right: '1%',
                    bottom: '5%',
                    // containLabel: true
                },
                dataZoom: [
                    {
                        type: 'inside',
                    }
                ],
                toolbox: {
                    show: true,
                    feature: {
                        magicType: { type: ['line', 'bar'] },
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    // type: 'category',
                    data: this.labels,
                    boundaryGap: false,
                    // interval: 12,
                    silent: false,
                    // nameLocation: "middle",
                    // name: this.xAxisLabel,
                    // nameGap: 25,
                    splitLine: {
                        show: false
                    },
                },
                yAxis: {
                    type: 'value',
                    nameLocation: "middle",
                    axisLabel: {
                        formatter: '{value}°C'
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#555555',
                        },
                        show: true,
                    },
                },
                series: this.data,
                stateAnimation: {
                    animation: "auto",
                    animationDuration: 1000,
                    animationDurationUpdate: 500,
                    animationEasing: "cubicInOut",
                    animationEasingUpdate: "cubicInOut",
                    animationThreshold: 2000,
                    progressiveThreshold: 3000,
                    progressive: 400,
                    hoverLayerThreshold: 3000,
                    useUTC: false
                },
            }
        };
    }
};
</script>
  
<style scoped>
.chart {
    display: flex;
    flex-flow: column;
    height: 100%;
    min-height: 450px
}
</style>